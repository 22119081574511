import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchParticipants,
    getParticipants,
} from '../../app/reducers/Participant/teamParticipantSlice';
import TableWithHeading from '../../components/Table/TableWithHeading';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SetButtons from '../../components/infrastructure/SetButtons';
import {
    editEvent,
    fetchEvent,
    fetchEvents,
    getEvents,
} from '../../app/reducers/Event/eventSlice';
import ViewDetailsOfParticipants from './ViewDetailsOfParticipants';
import {
    fetchCommittee,
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { ChevronRight, Download } from 'react-feather';
import { toast } from 'react-toastify';
import EventNotFound from '../EventNotFound';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { exportCsv } from '../../utils/Utils';
import { ClipLoader } from 'react-spinners';

const ViewParticipants = ({ actionFeatures }) => {
    const { user } = useSelector(getAuth);
    const { id, eventId } = useParams();
    const { teamParticipant } = useSelector(getParticipants);
    // console.log('parti', teamParticipant)
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const [canShowStopOTSE, setCanShowStopOTSE] = useState(true);
    const [canShowCertificate, setCanShowCertificate] = useState(true);
    const [requestData, setRequestData] = useState({});
    const [isDownloading, setIsDownloading] = useState({
        list: false,
        pc: false,
        po: false,
        poe: false,
    });
    const navigate = useNavigate();
    const { event, elementEditData } = useSelector(getEvents);
    const { committee, elementEditData: committeeEditData } =
        useSelector(getCommittees);
    // console.log('committee', committee);
    const { features } = useSelector(getFeatures);
    const eventData = useMemo(
        () => event?.docs?.filter((ele) => ele?.committee?._id == id),
        [event]
    );
    const committeeNameData = committee?.docs?.find(
        (single) => single?._id === id
    );
    const eventNameData = eventData?.find((single) => single?._id === eventId);
    const dispatch = useDispatch();

    const actionsBtn = ['Stop OTSE', 'Participant Certificate'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures?.forEach((ids, index) => {
            config[ids] = actions[index];
        });
        return config;
    };

    const computeActiveFeature = () => {
        const buttonStateSetter = {
            'Stop OTSE': setCanShowStopOTSE,
            'Participant Certificate': setCanShowCertificate,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        console.log(' obj action map', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    if (actionFeatures.includes(item?._id)) {
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };

    useEffect(() => {
        if (eventId) {
            dispatch(fetchEvent({ _id: eventId }));
            dispatch(
                fetchParticipants({
                    'event._id': eventId,
                    status: 'approved',
                    populate: true,
                    sort: { createdAt: 1 },
                    // type: { $ne: 'ncp' },
                })
            );
            dispatch(
                fetchEvents({
                    populate: true,
                    'committee._id': id,
                    sort: { order: 1 },
                })
            );
        }
        if (id) {
            dispatch(fetchCommittee({ _id: id }));
        }
        dispatch(fetchCommittees({ populate: true }));
    }, [id, eventId]);

    // useEffect(() => {
    //     if (eventId) {
    //         const index = eventData?.findIndex((ele) => ele?._id === eventId);
    //         console.log('index find', index)
    //         if (index !== -1) {
    //             setActiveButtonIndex(index);
    //         }
    //     }
    // }, [eventId, eventData, committee]);

    useEffect(() => {
        computeActiveFeature();
    }, [features]);

    const participantsCols = [
        {
            Header: 'CC Code',
            accessor: 'collegeCode',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.type === 'otse'
                            ? `${row?.original?.collegeCode} ( ${row?.original?.mainCode} )`
                            : row?.original?.collegeCode}
                    </span>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        // {
        //     Header: 'College',
        //     Cell : ({row})=>{
        //         console.log('row', row?.original)
        //         return (
        //             <span>{row?.original?.college?.name}</span>
        //         )
        //     },
        //     cellStyle:{
        //         width : "20%"
        //     }
        // },
        {
            Header: 'Details',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-2">
                        <PrimaryButton
                            type="button"
                            onClick={async (e) => {
                                e.stopPropagation();
                                setRequestData(row?.original);
                                setShowApprovedModal(true);
                            }}
                        >
                            View Participants
                        </PrimaryButton>
                    </div>
                );
            },
            cellStyle: {
                width: '60%',
            },
        },
    ];

    const participantsMemo = useMemo(
        () => (teamParticipant?.docs ? teamParticipant?.docs : []),
        [teamParticipant]
    );
    return (
        <PageWithCard
            heading={
                eventId ? (
                    <>
                        <Link to={'/committee/event'}>Events</Link>
                        <span className="text-slate-200 inline-block px-1">
                            <ChevronRight size={14} className="-mb-0.5" />{' '}
                        </span>
                        <span>{committeeNameData?.name}</span>
                        <span className="text-slate-200 inline-block px-1">
                            <ChevronRight size={14} className="-mb-0.5" />{' '}
                        </span>
                        <span>{'Participants'}</span>
                        {eventData?.length ? (
                            <>
                                <span className="text-slate-200 inline-block px-1">
                                    <ChevronRight
                                        size={14}
                                        className="-mb-0.5"
                                    />{' '}
                                </span>
                                <span>{eventNameData?.name}</span>
                            </>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    'Participants'
                )
            }
        >
            {eventId && eventData?.length > 0 ? (
                <SetButtons>
                    <div className="flex items-center justify-start divide-x divide-slate-600">
                        {eventData?.map((ele, index) => (
                            <Link
                                key={ele?._id}
                                to={`/committee/event/viewParticipant/${id}/${ele?._id}`}
                            >
                                <button
                                    className={
                                        activeButtonIndex === index
                                            ? `btn  text-red-500 px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px] md:min-w-[0px]`
                                            : `btn text-slate-200 px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px] md:min-w-[0px]`
                                    }
                                    onClick={() => {
                                        setActiveButtonIndex(index);
                                    }}
                                >
                                    {ele?.name}
                                    {/* {truncateText(ele?.name, 10)} */}
                                </button>
                            </Link>
                        ))}
                    </div>
                </SetButtons>
            ) : (
                <EventNotFound />
            )}
            <ViewDetailsOfParticipants
                requestData={requestData}
                showApprovedModal={showApprovedModal}
                setShowApprovedModal={setShowApprovedModal}
            />
            {eventData?.length > 0 && (
                <TableWithHeading
                    columns={participantsCols}
                    data={participantsMemo}
                />
            )}
            {
                <div className="my-4 flex flex-row gap-4">
                    {elementEditData?.runningStatus === 'upcoming' &&
                        (committeeEditData?.scorer === user?._id ||
                            ['HOD', 'Super User', 'Developer'].includes(
                                user?.role?.name
                            )) &&
                        canShowStopOTSE && (
                            <DangerButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    let obj = {
                                        ...elementEditData,
                                        runningStatus: 'ongoing',
                                    };
                                    console.log('edit', obj);
                                    const confirm = window.confirm(
                                        'Are you sure you want to stop participant registration ?'
                                    );
                                    if (confirm) {
                                        const resp = await dispatch(
                                            editEvent(obj)
                                        );
                                        if (resp?.payload?.success) {
                                            toast.success(
                                                'OTSE stop successfully'
                                            );
                                        }
                                        await dispatch(
                                            fetchEvent({ _id: eventId })
                                        );
                                    }
                                }}
                            >
                                Stop OTSE
                            </DangerButton>
                        )}
                    {elementEditData?.runningStatus === 'ongoing' &&
                        ['HOD', 'Super User', 'Developer'].includes(
                            user?.role?.name
                        ) &&
                        canShowStopOTSE && (
                            <SecondaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    let obj = {
                                        ...elementEditData,
                                        runningStatus: 'upcoming',
                                    };
                                    console.log('edit', obj);
                                    const confirm = window.confirm(
                                        'Are you sure you want to start participant registration ?'
                                    );
                                    if (confirm) {
                                        const resp = await dispatch(
                                            editEvent(obj)
                                        );
                                        if (resp?.payload?.success) {
                                            toast.success(
                                                'OTSE start successfully'
                                            );
                                        }
                                        await dispatch(
                                            fetchEvent({ _id: eventId })
                                        );
                                    }
                                }}
                            >
                                Start OTSE
                            </SecondaryButton>
                        )}
                    {elementEditData?.runningStatus === 'ended' &&
                        ['HOD', 'Super User', 'Developer'].includes(
                            user?.role?.name
                        ) &&
                        canShowStopOTSE && (
                            <DangerButton
                                disabled={true}
                                style={{
                                    backgroundColor: '#212121',
                                    cursor: 'not-allowed',
                                    color: '#c2c2c2',
                                }}
                            >
                                Start OTSE
                            </DangerButton>
                        )}
                    {isDownloading.list ? (
                        <ClipLoader color="#FFFFFF" size={18} />
                    ) : (
                        (committeeEditData?.scorer === user?._id ||
                            [
                                'HOD',
                                'Super User',
                                'Developer',
                                'Head',
                                'Admin Head',
                            ].includes(user?.role?.name)) && (
                            <SecondaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    try {
                                        setIsDownloading((state) => ({
                                            ...state,
                                            list: true,
                                        }));
                                        let data = {
                                            populate: true,
                                            status: 'approved',
                                            'event._id': elementEditData?._id,
                                        };

                                        const stringData =
                                            QueryString.stringify(data);
                                        const resp =
                                            await authAxiosInstance.get(
                                                `/teamParticipant?${stringData}`
                                            );
                                        // console.log(
                                        //     'dwnld data',
                                        //     resp?.data?.data?.docs
                                        // );
                                        if (
                                            resp?.data?.data?.docs?.length > 0
                                        ) {
                                            let mainData =
                                                resp?.data?.data?.docs?.map(
                                                    (ele, index) => {
                                                        let {
                                                            college,
                                                            event,
                                                            collegeCode,
                                                            ncpCollege,
                                                            mainCode,
                                                            status,
                                                            participants,
                                                            substitutes,
                                                        } = ele;
                                                        return {
                                                            srNo: `${index + 1}`,
                                                            event: event?.name,
                                                            college: college
                                                                ? college?.name
                                                                : ncpCollege,
                                                            status: status,
                                                            collegeCode:
                                                                collegeCode,
                                                            mainCode: mainCode,
                                                            participants:
                                                                participants
                                                                    ?.filter(
                                                                        (
                                                                            ele
                                                                        ) => {
                                                                            return (
                                                                                ele.participantStatus ===
                                                                                'regular'
                                                                            );
                                                                        }
                                                                    )
                                                                    ?.map(
                                                                        (p) =>
                                                                            `${p.firstName} ${p.lastName}`
                                                                    )
                                                                    .join(', '),
                                                            substitutes:
                                                                participants
                                                                    ?.filter(
                                                                        (
                                                                            ele
                                                                        ) => {
                                                                            return (
                                                                                ele.participantStatus ===
                                                                                'substitute'
                                                                            );
                                                                        }
                                                                    )
                                                                    ?.map(
                                                                        (p) =>
                                                                            `${p.firstName} ${p.lastName}`
                                                                    )
                                                                    .join(', '),
                                                            substituted:
                                                                substitutes
                                                                    ?.map(
                                                                        (s) =>
                                                                            `${s.firstName} ${s.lastName}`
                                                                    )
                                                                    .join(', '),
                                                        };
                                                    }
                                                );
                                            // console.log('main data', mainData);
                                            const fileName = `${elementEditData?.name} List`;
                                            exportCsv(mainData, fileName);
                                        }
                                    } catch (error) {
                                        console.log(error);
                                        toast.error('Fail to Download');
                                    } finally {
                                        setIsDownloading((state) => ({
                                            ...state,
                                            list: false,
                                        }));
                                    }
                                }}
                            >
                                Download Participants Csv{' '}
                                <Download size={14} className="ml-2" />
                            </SecondaryButton>
                        )
                    )}
                </div>
            }
            {
                <div className="my-4 flex flex-row gap-4">
                    {isDownloading.pc ? (
                        <ClipLoader color="#FFFFFF" size={18} />
                    ) : (
                        canShowCertificate && (
                            <SecondaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    try {
                                        setIsDownloading((state) => ({
                                            ...state,
                                            pc: true,
                                        }));
                                        const string = QueryString.stringify({
                                            populate: true,
                                            limit: 5000,
                                        });
                                        const resp =
                                            await authAxiosInstance.get(
                                                `/teamParticipant/getParticipantData?${string}`
                                            );
                                        // console.log('resp parti', resp?.data?.data);
                                        const participantData =
                                            resp?.data?.data;
                                        const eventParticipantData =
                                            participantData?.filter(
                                                (ele) =>
                                                    ele?.event?._id === eventId
                                            );
                                        // console.log('resp parti2', eventParticipantData);
                                        const response =
                                            await authAxiosInstance.post(
                                                '/printable/participantCertificatePdf',
                                                eventParticipantData
                                                // { responseType: 'blob' }
                                            );
                                        const printWindow = window.open(
                                            '',
                                            '_blank'
                                        );
                                        printWindow.document.write(
                                            response.data
                                        );
                                        // const fileName = `${elementEditData?.name} Participant Certificate.pdf`;
                                        // saveAs(response.data, fileName);
                                    } catch (error) {
                                        console.log('Error', error);
                                        toast.error('Failed to Download');
                                    } finally {
                                        setIsDownloading((state) => ({
                                            ...state,
                                            pc: false,
                                        }));
                                    }
                                }}
                            >
                                Participants Certificate{' '}
                                <Download size={14} className="ml-2" />
                            </SecondaryButton>
                        )
                    )}
                    {isDownloading.po ? (
                        <ClipLoader color="#FFFFFF" size={18} />
                    ) : (
                        <SecondaryButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                try {
                                    setIsDownloading((state) => ({
                                        ...state,
                                        po: true,
                                    }));
                                    const string = QueryString.stringify({
                                        populate: true,
                                        limit: 5000,
                                    });
                                    const resp = await authAxiosInstance.get(
                                        `/teamParticipant/getParticipantData?${string}`
                                    );
                                    // console.log('resp parti', resp?.data?.data);
                                    const participantData = resp?.data?.data;
                                    const eventParticipantData =
                                        participantData?.filter(
                                            (ele) =>
                                                ele?.event?._id === eventId &&
                                                ele.type === 'otse'
                                        );
                                    // console.log(
                                    //     'resp parti2',
                                    //     eventParticipantData
                                    // );
                                    const response =
                                        await authAxiosInstance.post(
                                            '/printable/popPdf',
                                            eventParticipantData,
                                            { responseType: 'blob' }
                                        );
                                    const fileName = `${elementEditData?.name} Pop Otse.pdf`;
                                    saveAs(response.data, fileName);
                                } catch (error) {
                                    console.log('Error', error);
                                    toast.error('Failed to Download');
                                } finally {
                                    setIsDownloading((state) => ({
                                        ...state,
                                        po: false,
                                    }));
                                }
                            }}
                        >
                            POP OTSE <Download size={14} className="ml-2" />
                        </SecondaryButton>
                    )}
                    {isDownloading.poe ? (
                        <ClipLoader color="#FFFFFF" size={18} />
                    ) : (
                        <SecondaryButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                try {
                                    setIsDownloading((state) => ({
                                        ...state,
                                        poe: true,
                                    }));
                                    const string = QueryString.stringify({
                                        populate: true,
                                        limit: 5000,
                                    });
                                    const resp = await authAxiosInstance.get(
                                        `/teamParticipant/getParticipantData?${string}`
                                    );
                                    // console.log('resp parti', resp?.data?.data);
                                    const participantData = resp?.data?.data;
                                    const eventParticipantData =
                                        participantData?.filter(
                                            (ele) =>
                                                ele?.event?._id === eventId &&
                                                ele.type === 'otse' &&
                                                ele.status === 'eliminate'
                                        );
                                    // console.log(
                                    //     'resp parti2',
                                    //     eventParticipantData
                                    // );
                                    const response =
                                        await authAxiosInstance.post(
                                            '/printable/popEliminationPdf',
                                            eventParticipantData,
                                            { responseType: 'blob' }
                                        );
                                    const fileName = `${elementEditData?.name} Pop Otse Elimination.pdf`;
                                    saveAs(response.data, fileName);
                                } catch (error) {
                                    console.log('Error', error);
                                    toast.error('Failed to Download');
                                } finally {
                                    setIsDownloading((state) => ({
                                        ...state,
                                        poe: false,
                                    }));
                                }
                            }}
                        >
                            POP OTSE Elimination{' '}
                            <Download size={14} className="ml-2" />
                        </SecondaryButton>
                    )}
                </div>
            }
        </PageWithCard>
    );
};

export default ViewParticipants;
